import "./App.scss";
import React from "react";
import logo from "./assets/logos/logo.jpeg";

function App() {
  return (
    <div className="App">
      <body>
        <header>
          <h1>We'll Be Back Soon!</h1>
          <p>
            Great things are coming! We're putting the finishing touches on our
            website. Stay tuned!
          </p>
          <img
            src={logo}
            alt="Just Kaptured Productions Logo"
            className="round-logo"
          />
        </header>
        <section class="contact-info">
          <h2>Contact Us</h2>
          <h4>Jordan Klebe</h4>
          <h5>Founder & CEO</h5>
          <h3>Just Kaptured Productions</h3>
          <button>
            {" "}
            <a href="mailto:justkapturedproductions@gmail.com ">
              <i class="fas fa-envelope"></i> Request a Personalized Quote
            </a>
          </button>
          <button>
            <a
              href="sms:+14435230792"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fas fa-sms"></i> Let’s Chat
            </a>
          </button>
          {/* <button>
            <a
              href="https://api.whatsapp.com/send?phone=+14435230792"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chat on WhatsApp
            </a>
          </button> */}
          <button>
            <a
              href="https://www.youtube.com/@JustKapturedProductions"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fab fa-youtube"></i> Explore Our YouTube Channel{" "}
            </a>{" "}
          </button>
          <button>
            <a
              href="https://www.instagram.com/JustKapturedProductions"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fab fa-instagram"></i> Follow Us On Instagram
            </a>{" "}
          </button>
        </section>
        <footer>
          <h6>&copy; 2024 Just Kaptured Productions. All rights reserved.</h6>
        </footer>
      </body>
    </div>
  );
}

export default App;
